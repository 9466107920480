// overrides.js
import { useState } from "react"
import { Data, Override } from "framer"

const appState = Data({
    amount: "",
    totalPrice: 0,
})

export function usePriceCalculator(pricePerUnit = 89) {
    const handleInputChange = (event) => {
        const value = event.target.value
        if (!isNaN(value)) {
            appState.amount = value
            appState.totalPrice = value ? parseFloat(value) * pricePerUnit : 0
        }
    }

    return {
        handleInputChange,
        totalPrice: `$${appState.totalPrice.toFixed(2)}`,
    }
}

export function InputBoxOverride(pricePerUnit) {
    const { handleInputChange } = usePriceCalculator(pricePerUnit)

    return {
        onChange: handleInputChange,
    }
}

export function PriceDisplayOverride(pricePerUnit) {
    const { totalPrice } = usePriceCalculator(pricePerUnit)

    return {
        text: `Total Price: ${totalPrice}`,
    }
}
